
import React from 'react';
import Global from '../layout/global';

import './404.scss';

/**
 * Default 404 doesn't exist markup.
 */
const FourOhFour = () => {
    return (
        <Global
            page="fourohfour"
            background="/img/background/homepage2.jpg">
            <div class="fourohfour-inner">
                <h1>=[</h1>
                <b className="glitch" data-text="Four oh four.">Four oh four.</b>
                <a href="https://www.imdb.com/title/tt8084164/">
                    Sorry we could not find the page you requested. We're all
                    living in each others paranoia.
                </a>
            </div>
        </Global>
    );
};

export default FourOhFour;
